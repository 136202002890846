import React from "react";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";

import "./Checkout.css";
import Amplify from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

const Checkout = () => {
  const stripePromise = loadStripe("pk_live_4nGA6EA6gOi0kWrthgcmdybx");

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <React.Fragment>
      <div className="BoxAroundAmplifySignOut">
        <div hidden className="margin20PXCenter colorRed">
          Right now, we are experiencing technical difficulties with Amazon Web
          Services. We are sorry that we cannot accept Credit Card payment at
          this moment. Please use Zelle Pay. Please send money to
          max.li@live.com. Notify me by calling 615-208-3675. Thank you. Max Li
        </div>
        <div className="margin20PXCenter">Hello, {user.username}</div>
      </div>
      <section className="checkout-wrapper">
        <AmplifyAuthenticator>
          <Elements stripe={stripePromise}>
            <section>
              <h3 className="">Information Needed for Checkout:</h3>
              <CheckoutForm />
            </section>
          </Elements>
        </AmplifyAuthenticator>
      </section>
    </React.Fragment>
  ) : (
    <section className="checkout-wrapper">
      <AmplifyAuthenticator>
        <Elements stripe={stripePromise}>
          <section>
            <h3 className="">Information Needed to Checkout:</h3>
            <CheckoutForm />
          </section>
        </Elements>
      </AmplifyAuthenticator>
    </section>
  );
};

export default Checkout;
