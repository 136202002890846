import React from "react";

import "./Faqs.css";

const Faqs = () => {
  return (
    <React.Fragment>
      <div className="superBlock">
        <div className="qaBlock">
          <div className="question">
            Question 1. I heard a lot of good things about these latest
            technology. What are benefits of studying artificial intelligence,
            machine learning and deep learning?
          </div>
          <div className="answer">
            Answer: There are many benefits. First, the average salary for
            machine learning engineer in US is around 150K, which is much higher
            than other professions' salaries. Second, there is a skill shortage
            in the US job market. Third, the demand for those skills are
            universal. Even if you do not want to major in CS, AI and ML, or do
            not want to work on ML projects, you will find that other fields use
            ML extensively. All kinds of advanced technology depend on machine
            learning. No exception.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 2. Can I learn those skills without paying money?
          </div>
          <div className="answer">
            Answer: Yes, you can. I strongly encourage you to try free resource
            first before committing to paid learning channels. Usual free
            resources are the official sources, such as AWS machine learning's
            website and TensorFlow's website, YouTube vidoes, wikipedia
            articles. However, most learners need individualized guidances. I am
            the one that can help you. Once you have got enough background
            knowledge, you should learn new skills by yourself without any
            supervision, hopefully without paying anyone.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">Question 3. Is it expensive? </div>
          <div className="answer">
            Answer: No. Each session is 20 dollars and each class consists of 4
            sessions. We can finish one course in a month. The total fee for one
            class is 80 dollars.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 4. What is the format of the class?
          </div>
          <div className="answer">
            Answer: Each session is 50 minutes long. We will teach through Zoom
            meetings. Right now, all classes are at nights on Fridays, Saturdays
            and Sundays.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 5. What is your teaching style?
          </div>
          <div className="answer">
            Answer: I hate boring lectures and I don't want to have "death by
            PowerPoint" in my class. All sessions are hands-on. I will let codes
            speak for themselves. I will teach students how to write, debug and
            improve their codes. I will spend a lot of time remotely controlling
            their computers so that I can fix their issues.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">Question 6. Is it really for all ages?</div>
          <div className="answer">
            Answer: Yes. In my current classes, I have people with ages from 8
            to 38. Age is not an issue at all. All that is essential is the
            strong desire to learn new skills.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 7. I heard that machine learning is math heavy. Can I still
            learn it if I am not good at math?
          </div>
          <div className="answer">
            Answer: Yes, you can do it without any math. There is a common
            misconception that you need to be a math genius in order to do
            machine learning. As a user of any machine learning/deep learning
            frameworks, you only need to know how to write codes to call their
            APIs. Understanding the internal works of deep learning is optional.
            Let me give you an example. Almost all of us know how to drive a car.
            However, only a small portion of us know how the internal combustion
            engine inside a car works. Having no knowledge with internal
            combustion engine does not prevent anyone from becoming an excellent
            driver.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 8. What is the computing environment?
          </div>
          <div className="answer">
            Answer: We are going to use Colaboratory (or colab for short) within
            a browser. Colab is a free services from Google and the only
            requirement is a free Gmail account. MacOS or Linux machines are
            fine, but I prepare my classes on Windows 10. It would be great if
            you can get a Windows 10 machine so that we are on the same page.
            You need to have Zoom installed because it is a remote, in-person
            learning through Zoom meetings.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">
            Question 9. What happen if I cannot attend the class for good
            reasons?
          </div>
          <div className="answer">
            Answer. I will record the teaching session so that students can
            watch later on. However, I strongly encourage them to attend live
            sessions for better results.
          </div>
        </div>
        <div className="qaBlock">
          <div className="question">Question 10. How to contact you?</div>
          <div className="answer">
            Answer: email: max@recomputing.us Cell phone: 615-208-3675
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faqs;
