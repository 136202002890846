import React from "react";

import { withRouter } from "react-router-dom";

// import { useParams, useHistory } from "react-router-dom";
// import { BookContext } from "../context/books";
// import { CartContext } from "../context/cart";
import "./PaymentSuccessful.css";
const PaymentSuccessful = () => {
  return (
    <React.Fragment>
      <p className="divisionCenter narrowWidth">
        Payment is processed Sucessfully. You should receive a confirmation
        email in a few minutes. Please check the spam folder of your email
        system because the email may be classified as junk email. Contact us if
        you do not receive an email after one hour.
      </p>
      <div className="divisionCenter">
        <a href="/" className="btn btn-primary ">
          Return to the main page
        </a>
      </div>
      <div className="divisionCenter">
        <a href="/courses" className="btn btn-primary ">
          browse courses
        </a>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PaymentSuccessful);
