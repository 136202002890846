import React from "react";

import { withRouter } from "react-router-dom";

// import { useParams, useHistory } from "react-router-dom";
// import { BookContext } from "../context/books";
// import { CartContext } from "../context/cart";
import "./PaymentFailed.css";
const PaymentFailed = () => {
  return (
    <React.Fragment>
      <p className="divisionCenter narrowWidth">
        Payment processing is failed. Please check your address and credit/debit
        card information carefully. You can try again.
      </p>
      <div className="divisionCenter">
        <a href="/checkout" className="btn btn-primary ">
          Back to checkout page
        </a>
      </div>
      <div className="divisionCenter">
        <a href="/" className="btn btn-primary ">
          Return to main page
        </a>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PaymentFailed);
