import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Amplify
import Amplify from "aws-amplify";

// Pages
import Home from "./pages/Home";
import Error from "./pages/Error";
import Books from "./pages/Books";
import Faqs from "./pages/Faqs";
import Cart from "./pages/Cart";
import Qualifications from "./pages/Qualifications";
import Checkout from "./pages/Checkout";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import PaymentFailed from "./pages/PaymentFailed";
import BookDetails from "./pages/BookDetails";
import Admin from "./pages/Admin";

import "bootstrap/dist/css/bootstrap.min.css";

// Components
import Header from "./components/Header";

// Amplify Configurations
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path="/checkout">
          <Checkout />
        </Route>
        <Route path="/qualifications">
          <Qualifications />
        </Route>
        <Route exact path="/faqs">
          <Faqs />
        </Route>
        <Route exact path="/PaymentSuccessful">
          <PaymentSuccessful />
        </Route>
        <Route exact path="/PaymentFailed">
          <PaymentFailed />
        </Route>

        <Route exact path="/courses">
          <Books />
        </Route>
        <Route
          path="/courses/:id"
          children={<BookDetails></BookDetails>}
        ></Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
