import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import { BookContext } from "../context/books";

import "./Home.css";

import SkillShortage from "../assets/cable-break.png";
import HigherSalary from "../assets/salary.png";
import UbiquitousPresence from "../assets/world.svg";

const Home = () => {
  const { featured } = useContext(BookContext);
  if (!featured.length) {
    return <h3>No Featured ML Courses</h3>;
  }
  return (
    <React.Fragment>
      <Hero />
      <section className="featured">
        <header className="featured-head">
          <h3 className="">Featured Courses</h3>
        </header>
        <div className="books featured-list">
          {featured.map(({ id, image, title }) => (
            <article key={id} className="book featured-book">
              <div className="book-image">
                <img src={image} alt={title} />
              </div>
              <Link to={`courses/${id}`} className="btn book-link">
                details
              </Link>
            </article>
          ))}
        </div>
      </section>
      <div className="benefitsContainer">
        <div className="benefits textAlignCenter">Benefits</div>
        <div className="flexBox">
          <div className="flexItem">
            <div className="textAlignCenter">
              <img
                className="imageStyle"
                src={HigherSalary}
                alt="Higher Salary"
              />
            </div>
            <div className="textAlignCenter benefitItems">Higher Salary</div>
            <div className="textAlignCenter">
              According to indeed.com, the avareage salary of a Machine Learning
              engineer is
              <a href="https://www.indeed.com/career/machine-learning-engineer/salaries">
                &nbsp;$150,804
              </a>
              .
            </div>
          </div>
          <div className="flexItem">
            <div className="textAlignCenter">
              <img
                className="imageStyle"
                src={SkillShortage}
                alt="skill shortage"
              />
            </div>
            <div className="textAlignCenter benefitItems">Skill Shortage</div>
            <div className="textAlignCenter">
              According to ZDNet.com, Two in five companies see lack of
              technical expertise as a roadblock to AI.
              <a href="https://www.zdnet.com/article/artificial-intelligence-skills-shortages-re-emerge/">
                &nbsp;Read more...
              </a>
            </div>
          </div>

          <div className="flexItem">
            <div className="textAlignCenter">
              <img
                className="imageStyle"
                src={UbiquitousPresence}
                alt="Ubiquitous Presence"
              />
            </div>
            <div className="textAlignCenter benefitItems">
              Ubiquitous Presence
            </div>
            <div className="textAlignCenter">
              Even if you do not want to major in CS, AL or ML, or directly work
              on ML projects, you would find that other fields use ML
              extensively.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
