import React from "react";
// import { useParams, useHistory } from "react-router-dom";
// import { BookContext } from "../context/books";
// import { CartContext } from "../context/cart";
import "./Qualifications.css";
import AWSCertificate from "../assets/AWSCertifiedMachineLearningSpecialtyCertificate.png";
import AWSScoreReport from "../assets/AWSCertifiedMachineLearningSpecialtyScoreReport.png";
import DeathByPPT from "../assets/DeathByPowerPoint.png";
import UnionUniversityNyackCollege from "../assets/UnionUniversityNyackCollege.png";
import PhD from "../assets/PhD.png";
import MS from "../assets/MS.png";
const Qualifications = () => {
  return (
    <React.Fragment>
      <p className="centeredStuff">
        You have many choices in selecting the best way to learn Machine
        Learning Techniques. If you are highly capable, you can definitely learn
        a new skill by watching YouTube videos. However, many learners need
        live persons to teach them. Dr. Haifei Li (Max for short, Chinese name
        is 李海飞) is well qualified to teach machine learning courses for many
        reasons.
      </p>

      <p className="centeredStuff">
        <strong>Machine Learning Certification.</strong> He has successfully
        passed the AWS Certified Machine Learning - Speciality Exam on January
        29, 2021. Amazon Web Services (AWS) is one of the most famous providers
        of cloud computing services. AWS offers many services related to machine
        learning. ML exam from AWS was released in March 2019 and it is
        considered by machine learning experts to be one of the most difficult
        exams from AWS.
      </p>

      <p className="centeredStuff">
        Wish that you can indepdently verify the certification? No problem at
        all. You just need to go to{" "}
        <a className="specialAnchor" href="http://aws.amazon.com/verification">
          aws.amazon.com/verification
        </a>{" "}
        and enter the following VN (Validation Number) WDGZPY1JDB14QD9B
      </p>
      <div className="centerImageMargin">
        <img width="50%" src={AWSCertificate} alt="AWS Certificate" />
      </div>
      <div className="centerImageMargin">
        <img width="50%" src={AWSScoreReport} alt="AWS Score Report" />
      </div>
      <hr />
      <p className="centeredStuff">
        <strong>Teaching Experiences.</strong> He has taught in two higher
        education institutions for more than 10 years and he is hungry for new
        skills related to machine learning, artificial intelligence, programming
        and software development.
      </p>
      <div className="centerImageMargin">
        <img
          width="50%"
          src={UnionUniversityNyackCollege}
          alt="logos of Union University and Nyack College"
        />
      </div>
      <hr />
      <p className="centeredStuff">
        <strong>Hands-on Approach.</strong> He hates "Death by PowerPoint".
        Instead, he wants all participtants to do "Learning by Doing". He can
        fix computer bugs in participants' code. If necessary, he would control
        participants' Zoom sessions and actively work with them side-by-side,
        virtually.
      </p>
      <div className="centerImageMargin">
        <img
          width="50%"
          src={DeathByPPT}
          alt="Death by PPT and Engaged Listener"
        />
      </div>
      <hr />

      <p className="centeredStuff">
        <strong>Advanced Degrees.</strong> He got his PhD and Master's degree
        from the University of Florida (UF). UF is a top-tier public unviersity
        in the nation. UF consistently ranks Number 1 in the great state of
        Florida. Go Gators!
      </p>

      <div className="centerImageMargin">
        <img width="50%" src={PhD} alt="Max Li PhD Diploma" />
      </div>
      <div className="centerImageMargin">
        <img width="50%" src={MS} alt="Max Li Master of Science Diploma" />
      </div>
    </React.Fragment>
  );
};

export default Qualifications;
