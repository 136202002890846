import React from "react";
// import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import "./Header.css";

// import NavDropdown from "react-bootstrap/NavDropdown";
// import Form from "react-bootstrap/Form";
// import FormControl from "react-bootstrap/FormControl";
// import Button from "react-bootstrap/Button";

const Header = () => {
  return (
    <React.Fragment>
      {/* // <header className="main-head">
    //   <nav>
    //     <h1>
    //       <a
    //         id="logo"
    //         href="https://recomputing.us"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         Recomputing Inc
    //       </a>
    //     </h1>
    //     <ul>
    //       <li>
    //         <Link to="/">Home</Link>
    //       </li>
    //       <li>
    //         <Link to="/qualifications">Qualifications</Link>
    //       </li>
    //       <li>
    //         <Link to="/courses">Courses</Link>
    //       </li>
    //       <li>
    //         <Link to="/cart">Cart</Link>
    //       </li>
    //       <li>
    //         <Link to="/checkout">Checkout</Link>
    //       </li>
    //     </ul>
    //   </nav>
    // </header> */}

      <Navbar bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="#home">Recomputing Inc</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navBarSize" fill variant="pills">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/qualifications">Qualifications</Nav.Link>
            <Nav.Link href="/courses">Courses</Nav.Link>
            <Nav.Link href="/cart">Cart</Nav.Link>
            <Nav.Link href="/checkout">Checkout</Nav.Link>

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
