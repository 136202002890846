import React, { useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";

const BookDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { books } = useContext(BookContext);
  const { addToCart } = useContext(CartContext);

  const [dates, setDates] = useState("");

  const book = books.find((book) => {
    return book.id === id;
  });
  if (!book) {
    return <h3>Loading....</h3>;
  }

  const { image: url, title, description, author, price } = book;

  return (
    <section className="book-details">
      <div className="detail-image">
        <img src={url} alt="" />
      </div>
      <div className="detail-description">
        <h2>{title}</h2>
        <p>{description}</p>

        <h4>Please choose your dates below:</h4>

        <h4>
          <table
            className="table table-warning table-resonsive 
          table-striped table-bordered table-hover"
          >
            <tbody>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="dates"
                    value={dates}
                    onChange={() => {
                      setDates("Year: 2021, Month: 08, Day: 06, 13, 20, 27");
                    }}
                  />
                </td>
                <td>Year: 2021, Month: 08, Day: 06, 13, 20, 27</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="dates"
                    value={dates}
                    onChange={() => {
                      setDates("Year: 2021, Month: 09, Day: 03, 10, 17, 24");
                    }}
                  />
                </td>
                <td>Year: 2021, Month: 09, Day: 03, 10, 17, 24</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="dates"
                    value={dates}
                    onChange={() => {
                      setDates("Year: 2021, Month: 10, Day: 01, 08, 15, 22");
                    }}
                  />
                </td>
                <td>Year: 2021, Month: 10, Day: 01, 08, 15, 22</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="dates"
                    value={dates}
                    onChange={() => {
                      setDates("Year: 2021, Month: 11, Day: 05, 12, 19, 26");
                    }}
                  />
                </td>
                <td>Year: 2021, Month: 11, Day: 05, 12, 19, 26</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="radio"
                    name="dates"
                    value={dates}
                    onChange={() => {
                      setDates(
                        "Year: 2021, Month: 12, Day: 03, 10, 17, 27" +
                          " (24 is changed to 27 " +
                          " becuase 24 is Christmas Eve)"
                      );
                    }}
                  />
                </td>
                <td>
                  Year: 2021, Month: 12, Day: 03, 10, 17, 27 <br />
                  (24 is changed to 27 <br />
                  becuase 24 is Christmas Eve)
                </td>
              </tr>
            </tbody>
          </table>
        </h4>
        <h3>{author}</h3>
        <h4 className="mb-5">Price - ${price}</h4>
        <button
          className="btn mb-5"
          onClick={() => {
            addToCart({ ...book, id }, dates);
            history.push("/cart");
          }}
        >
          Add to Cart
        </button>
      </div>
    </section>
  );
};

export default BookDetails;
