import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
// import Nav from "react-bootstrap/Nav";
const Hero = () => {
  return (
    <section className="hero">
      <h1 className=" googleredcolor  firstLargeFont">
        Machine Learning Courses
      </h1>
      <h4 className="googleyellowcolor secondLargeFont marginBottom20px">
        Artificial Intelligence is the new electricity <br /> Machine learning
        (ML) is the most practical and useful part of AI
      </h4>
      <Link className="btn" to="/courses">
        View All Courses
      </Link>
      <Link className="btn topMargin bottomMargin in-one-line" to="/faqs">
        Frequently Asked Questions
      </Link>
    </section>
  );
};

export default Hero;
