/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ed40fe91-7b88-41d7-aec9-036bb7ac534d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KSme1XT2m",
    "aws_user_pools_web_client_id": "5jframpvcpv0i3b3pv4i9kl9o1",
    "oauth": {},
    "aws_user_files_s3_bucket": "shop4recomputing92764fd50f63409ba73b02a67beae8a202936-main",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://nmklv4rzw5dvfkwop3s6q273du.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7hhbwb7iwvbtdffzx644bbucc4"
};


export default awsmobile;
