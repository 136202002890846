import React from "react";

const Error = () => {
  return (
    <div>
      <h2>Oops. Error Page. Please try again later.</h2>
    </div>
  );
};

export default Error;
