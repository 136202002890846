import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import { BookContext } from "../context/books";
import { CartContext } from "../context/cart";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Rubik, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = () => {
  const { cart, total } = useContext(CartContext);
  // const { clearCart } = useContext(CartContext);
  const { checkout } = useContext(BookContext);
  const [orderDetails, setOrderDetails] = useState({
    cart,
    total,
    address: null,
    token: null,
  });
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (orderDetails.token) {
      checkout(orderDetails);
      //clearCart();
      //history.push("/");
    }
    // eslint-disable-next-line
  }, [orderDetails]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
      // alert(
      //   "There is an error when processing your payment. Please check carefully and try again."
      // );
      history.push("/PaymentFailed");
    } else {
      setError(null);
      // Send the token to your server.
      const token = result.token;
      setOrderDetails({ ...orderDetails, token: token.id });
      // alert(
      //   "Payment processed sucessfully. " +
      //     "Check your email's spam folder if you did not receive " +
      //     "a confirmation email within 5 minutes. " +
      //     "Contact us if you have charged many times. \n"
      // );
      // return <Redirect to="/PaymentSuccessful" />;
      history.push("/PaymentSuccessful");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkout-form">
        <label htmlFor="checkout-address">Complete Billing Address</label>
        <input
          id="checkout-address"
          type="text"
          onChange={(e) =>
            setOrderDetails({ ...orderDetails, address: e.target.value })
          }
        />
        <div className="stripe-section margin20PXBorder1px">
          <label htmlFor="stripe-element"> Credit or debit card </label>
          <CardElement
            id="stripe-element"
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
        </div>
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <button type="submit" className="btn">
        Submit Payment
      </button>
    </form>
  );
};

export default CheckoutForm;
